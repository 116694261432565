import LazyLoading from './lib/lazy-loading.js'
import ContactForm from './template-parts/sections/contact-form.js'
import LoginForm from './template-parts/sections/login-form.js'

// import HSGrid from './template-parts/sections/grid.js'
// import Product from './templates/single-product.js'

export default class ModulesLoader {
  constructor (el = document) {
    this.el = el
  }

  loadModules() {
    window.lazyLoading = new LazyLoading()
    window.contactForm = new ContactForm()

    window.loginForm = new LoginForm()

    // console.log(window.loginForm);
  }


  loadJS (file, callback) {
    // DOM: Create the script element
    var jsElm = document.createElement('script')
    // set the type attribute
    jsElm.type = 'application/javascript'
    // make the script element load file
    jsElm.src = file
    // add a callback
    jsElm.addEventListener('load', callback)
    // finally insert the element to the body element in order to load the script
    document.body.appendChild(jsElm)
  }
}
