import consentUI from '../../lib/cookies/consent-ui'
import consentManager from '../../lib/cookies/consent-manager'

/*
Services. Check https://github.com/AmauriC/tarteaucitron.js/blob/master/tarteaucitron.services.js if you need to add more services.
Then adapt the service taking https://github.com/zemax/tartemeringuee/tree/master/services as an example.
*/
import gtag from 'tartemeringuee/services/gtag'

class Cookies {
  constructor() {
/*
    console.log('class Cookies');
    console.log('cookiesPolicyLink: ' + cookiesPolicyLink );
    console.log('googleAnalyticsId: ' + googleAnalyticsId );
*/
    if (typeof cookiesPolicyLink !== 'undefined') { /* eslint-disable */
      const consent = consentManager()
      if (typeof googleAnalyticsId !== 'undefined' && googleAnalyticsId !== '') {
        consent
          .register( gtag( googleAnalyticsId ) )
          .setUI( consentUI( {
                    'getText': getText( { 'privacyURL': cookiesPolicyLink } )
                  } ) )
          .launch()
      }
    }
  }
}

export default Cookies
